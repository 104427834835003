import React from 'react'

import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareYoutube } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function Footer() {


  const openLink = (url) => {
      window.open(url, '_blank'); 
   };




  return (
    <footer class="">
    <section class=" container">
      <section class=" up-foot row row-cols-1 row-cols-sm-2 row-cols-md-4 justify-content-between pt-5">
        <div class="col ps-5 ps-md-0">
          <a href="#"><img class="logo-side" src="images/logo-property-nexus-removebg-preview.png" alt="" /></a>
          <p class="para-text">Our vision is to change the way  people live in the world by making their lives more comfertable by taking the world to its best.</p>
          <p class="head-text">Total Free Customer Care</p>
          <p class="para-text">(+088) 123 456 789</p>
          <p class="head-text">LIVE SUPPORT?</p>
          <a class="para-text" href="#">help@propertynexus.com</a>
        </div>
        <div class="col">
          <ul class="foot-ul pt-5">
            <h6 class="text-white">COMPANY</h6>
            <li><a href="#">- About Us</a></li>
            <li><a href="#">- Careers</a></li>
            <li><a href="#">- Services</a></li>
            <li><a href="#">- Contact Us</a></li>
            <li><Link to="/TermsOfServices">- Trems And Conditions</Link></li>
            <li><Link to="Privacy_Policy">- Privacy Policy</Link></li>
            <li><a href="#">- Sitemap</a></li>
            <li><a href="#">- Blog</a></li>
          </ul>
        </div>
        <div class="col">
          <ul class="foot-ul pt-5">
            <h6 class="text-white">PROPERTIES IN INDIA</h6>
            <li><a href="#">- Property in New Delhi</a></li>
            <li><a href="#">- Property in Mumbai</a></li>
            <li><a href="#">- Property in Chennai</a></li>
            <li><a href="#">- Property in Pune</a></li>
            <li><a href="#">- Property in Noida</a></li>
            <li><a href="#">- Property in Gurgaon</a></li>
            <li><a href="#">- Property in Bangalore</a></li>
            <li><a href="#">- Property in Ahmedabad</a></li>
          </ul>
        </div>
        <div class="col">
          <ul class="foot-ul pt-5">
            <h6 class="text-white">POPULAR BHK SEARCHES</h6>
            <li><a href="#">- 2 BHK Flats in Pune</a></li>
            <li><a href="#">- 3 BHK Flats in Pune</a></li>
            <li><a href="#">- 2 BHK House for Sell in Pune</a></li>
            <li><a href="#">- 3 BHK House for Sell in Pune</a></li>
            <li><a href="#">- 2 BHK Villa for Sell in Pune</a></li>
            <li><a href="#">- 3 BHK Villa for Sell in Pune</a></li>
            <li><a href="#">- Property for Sell in Pune</a></li>
            <li><a href="#">- Flas in Pune</a></li>
            <li><a href="#">- Apartments in pune</a></li>
          </ul>
        </div>
    
          
      </section>
    </section>
    {/* <hr class="hr" /> */}
    <section class="down-foot container d-md-flex justify-content-between mt-4">
        <div class="social-media">
          <span>Follow us on Social Media </span>
          <a style={{ cursor: 'pointer' }}  onClick={() => openLink('https://www.facebook.com/profile.php?id=61561067648457')} ><FaFacebookSquare /></a>
          <a style={{ cursor: 'pointer' }} href="#"><FaTwitterSquare /></a> 
          <a style={{ cursor: 'pointer' }} onClick={() => openLink('https://linkedin.com/company/property-search-cepp')} ><FaLinkedin /></a>
           <a style={{ cursor: 'pointer' }}  href="#" ><FaSquareYoutube /></a>
           <a style={{ cursor: 'pointer' }} onClick={() => openLink('https://www.instagram.com/propertysearch.ai?igsh=MTZsbzhqbDJyeHhlbA==')} > <FaInstagram /></a>
        </div>
        <div class="copy">
            <p className='mb-0 fs-14'>©2022 by World Development Corporation Ltd, UK </p>
            <p className='fs-14'>(WorldDevCorp Technology & Business Solutions Private Limited)</p>

        </div>
    </section>
   </footer>
  )
}

export default Footer